import { callWithNuxt } from '#app';
import camelcase from 'lodash.camelcase';
import type { Seo } from '~/types';

type OperationType = 'Page' | 'Category'

export default async function useApiSeo(slug: string = '', type: OperationType = 'Page'): Promise<void> {
  if (!slug.length) return;

  const app = useNuxtApp();
  const route = useRoute();

  const { error, data: seo } = await useAsyncGql({
    operation: type,
    variables: {
      slug,
    },
    options: {
      transform: (data) => {
        const key = type === 'Page' ? 'page' : 'blog_category';
        return data[key]?.seo;
      },
    }
  })

  if (error.value || !seo.value) {
    return console.warn(`Failed to fetch SEO for "${route.path}" page.`);
  }

  const realSEO: Record<string, string | boolean> = {};

  for (const key of (Object.keys(seo.value)) as Array<keyof Seo>) {
    if (typeof seo.value[key] === 'string' && !(seo.value[key] as string).trim().length) continue;

    realSEO[camelcase(key)] = seo.value[key] as string | boolean;
  }

  if ('robots' in realSEO) {
    if (realSEO.robots === false) {
      realSEO.robots = 'noindex, nofollow';
    } else {
      delete realSEO.robots;
    }
  }

  callWithNuxt(app, useSeoMeta, [realSEO]);
}
